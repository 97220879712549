@import "_global";

.dna {
  $this:&;
  &__column {
    @include flex-grid-column(10,false);
    @include breakpoint(tablet-portrait down) {
      @include flex-grid-column(12,false);
    }
  }
  &--full {
    #{$this}{
      &__column {
        @include flex-grid-column(12,false);
      }
    }
  }
  &--visual {
    #{$this} {
      &__column {
        @include flex-grid-column(12,false);
      }
      &-card__images {
        #{$this}-card__image {
          @include flex-grid-column(12,false);
        }
      }
      &-card__image {
        &:before {
          content: none;
        }
      }
      &-card__content {
        @include flex-grid-column(6, false);
        margin-left: 0;
        @include breakpoint(tablet-portrait down) {
          @include flex-grid-column(12, false);
        }

      }
      &-card__content--medium {
        @include flex-grid-column(10,false);
        @include breakpoint(phone down) {
          @include flex-grid-column(12,false);
        }
        #{$this}-card__perex {
          margin: spacer(inner) auto 0 auto;
        }
      }
      &-card__inner {
        padding: 0 8.3333333333%;
        @include breakpoint(tablet-portrait down) {
          padding: 0;
        }
      }
    }
  }
}
